<template>
  <div>
    <Breadcrumbs main="" title="Dashboard" :display="true"/>
    <div>
        <b-row class="">
          <b-col>
              <b-row style="display: flex; align-items: center; margin-bottom: 5px;">
                  <b-col class="col-md-3">
                 <date-range-picker
                      style="display: inline;"
                      ref="picker"
                      opens="right"
                      :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                      :singleDatePicker="false"
                      :timePicker="false"
                      :timePicker24Hour="false"
                      :showWeekNumbers="false"
                      :showDropdowns="true"
                      :autoApply="false"
                      v-model="dateRange"
                      @update="init()"
                      :linkedCalendars="false"
                      :ranges="customDateRanges"
                      :max-date="new Date()"
                      class="date-range-custom"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        <!-- <span style="font-size: 80%;"> {{ changeDateFormate(dateRange.startDate) }} - {{ changeDateFormate(dateRange.endDate) }}</span>  -->
                      </template>
                    </date-range-picker>
                  </b-col>

                <!-- <b-col cols="9" class="text-right" >
                   <filter-view/>
                </b-col> -->
          </b-row>
        </b-col>
        <b-col lg="12">
          <content-page
            :edate="dateRange.endDate"
            :sdate="dateRange.startDate"
          ></content-page>
        </b-col>
        <b-col lg="12" class="mt-0">
          <b-card class="custom-modal-card-2">
            <span class="custome-title">Browsing</span>

            <div class="col-md-12" v-if="browsingLoading">
              <h6 class="sub-title mb-0 text-center">Loading...</h6>
              <div class="loader-box">
                <div class="loader-30"></div>
              </div>
            </div>
            <b-row
              v-else
              class="p-3"
              style="max-height: 60vh; overflow-y: scroll"
            >
              <b-card
                style="border-radius: 0.313vw; width: 15rem"
                v-for="item in bannerList"
                :key="item._id"
                type="gradient-red"
                class="ml-3 mt-1"
              >
                <div>
                  <div>
                    <span class="badge bg-dark p-2 custom-font-16 m-0">{{
                      adTypeArray.find((e) => e.id == item.sdocs.adtype).name
                    }}</span>
                    <span class="m-2 custom-font-16">{{
                      new Date(item.createdAt).toDateString()
                    }}</span>
                  </div>
                  <div style="margin: 5px; overflow: auto">
                    <span class="text-blue custom-font-26 cust_text">{{
                      item.spdocs.name + " - " + item.sdocs.title
                    }}</span>
                  </div>

                  <div>
                    <b-badge
                      :variant="
                        item.violationLvl == 'high'
                          ? 'danger'
                          : item.violationLvl == 'low'
                          ? 'warning'
                          : 'success'
                      "
                      class="m-2"
                      >{{ item.violationLvl }}</b-badge
                    >
                  </div>
                  <div @click="openServiceDetail(item)">
                    <img
                      :src="getImageLanding(item.trxid)"
                      alt="landing"
                      class="img_custom_style"
                      @error="$event.target.src = `../no_image.png`"
                    />
                  </div>

                  <b-badge
                    variant="warning"
                    style="font-size: 10px"
                    class="m-0"
                    >{{ item.country }}</b-badge
                  >
                </div>
              </b-card>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="12" class="mt-0">
          <b-card class="custom-modal-card-2">
            <span class="custome-title">Service Test</span>
            <div class="col-md-12" v-if="insightLoading">
              <h6 class="sub-title mb-0 text-center">Loading...</h6>
              <div class="loader-box">
                <div class="loader-30"></div>
              </div>
            </div>
            <b-row
              v-else
              class="p-3"
              style="max-height: 60vh; overflow-y: scroll"
            >
              <b-card
                style="border-radius: 0.313vw; width: 15rem"
                v-for="item in insightsList"
                :key="item._id"
                type="gradient-red"
                class="ml-3"
              >
                <div>
                  <div style="margin: 5px; overflow: auto">
                    <div>
                      <span class="badge bg-dark p-2 custom-font-16 m-0">{{
                        networks[item.netType] || ""
                      }}</span>
                      <span class="m-2 custom-font-16">{{
                        new Date(item.createdAt).toDateString()
                      }}</span>
                    </div>

                    <h4 class="text-blue custom-font-26 cust_text">
                      {{ item.sname + "-" + item.spname }}
                    </h4>
                  </div>

                  <div @click="openInsightTrx(item)">
                    <img
                      :src="getInsightTestHome(item.insight_id)"
                      alt="insight_test"
                      class="img_custom_style"
                      @error="$event.target.src = `../no_image.png`"
                    />
                  </div>
                </div>
              </b-card>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="12" class="mt-0">
          <b-card class="custom-modal-card-2">
            <span class="custome-title">APK Downloads</span>
            <div class="col-md-12" v-if="apkListLoading">
              <h6 class="sub-title mb-0 text-center">Loading...</h6>
              <div class="loader-box">
                <div class="loader-30"></div>
              </div>
            </div>
            <b-row
              v-else
              class="p-3"
              style="max-height: 60vh; overflow-y: scroll"
            >
              <b-card
                style="border-radius: 0.313vw; width: 15rem"
                v-for="item in apkList"
                :key="item._id"
                type="gradient-red"
                class="ml-3 mt-1"
              >
                <div>
                  <span class="badge bg-dark p-2 custom-font-16 m-0">{{
                    adTypeArray.find((e) => e.id == item.sdocs.adtype).name
                  }}</span>
                  <span class="m-2 custom-font-16">{{
                    new Date(item.createdAt).toDateString()
                  }}</span>
                </div>
                <div>
                  <div style="margin: 5px; overflow: auto">
                    <h4 class="text-blue custom-font-26 cust_text">
                      {{ item.spdocs.name + "-" + item.sdocs.title }}
                    </h4>
                  </div>

                  <div>
                    <b-badge
                      :variant="
                        item.violationLvl == 'high'
                          ? 'danger'
                          : item.violationLvl == 'low'
                          ? 'warning'
                          : 'success'
                      "
                      class="m-2"
                      >{{ item.violationLvl }}</b-badge
                    >
                  </div>
                  <div @click="openServiceDetail(item)">
                    <img
                      :src="getImageLanding(item.trxid)"
                      alt="landing"
                      class="img_custom_style"
                      @error="$event.target.src = `../no_image.png`"
                    />
                  </div>
                  
                  <b-badge
                    variant="warning"
                    style="font-size: 10px"
                    class="m-0"
                    >{{ item.country }}</b-badge
                  >
                </div>
              </b-card>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import ContentPage from "./Dashboard/Content.vue";
import dashbordService from "../../services/DashboardService";
import globalConfig from "../../services/globalLit";
import DateRangePicker from "vue2-daterange-picker";
import FilterView from "./Dashboard/FilterView.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import NetworkTypeService from "../../services/NetworkTypeService";
export default {
  name: "Dashboard",
  components: {
    ContentPage,
    DateRangePicker,
    FilterView,
  },
  data() {


    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date();

    const tenDaysBack = new Date();
    tenDaysBack.setDate(tenDaysBack.getDate() - 10);
    const startOfTenDays = new Date(tenDaysBack.setHours(0, 0, 0, 0));

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startOfYesterday = new Date(yesterday.setHours(0, 0, 0, 0));
    const endOfYesterday = new Date(yesterday.setHours(23, 59, 59, 999));

    return {
      browsingLoading: true,
      insightLoading: true,
      apkListLoading: true,
      dateRange: {
        startDate: startOfTenDays,
        endDate: endOfToday
      },
      


      customDateRanges: {
    'Today': [startOfToday, endOfToday],
        'Yesterday': [startOfYesterday, endOfYesterday],
    'Last 7 Days': [
      new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
      new Date(),
    ],
    'Last 30 Days': [
      new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000),
      new Date(),
    ],
    'This Week': [
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay(), 0, 0, 0),
      new Date(),
    ],
    'Last Week': [
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 7, 0, 0, 0),
      new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 1, 23, 59, 59),
    ],
    'Last 3 Weeks': [
      new Date(new Date().getTime() - 21 * 24 * 60 * 60 * 1000),
      new Date(),
    ],
    'This Month': [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ],
    'Last Month': [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0, 23, 59, 59),
    ],
    'Last 3 Months': [
      new Date(new Date().getFullYear(), new Date().getMonth() - 3, 1),
      new Date(),
    ],
  },
      adTypeArray: globalConfig.adTypeArray,
      apkList: [],
      insightsList: [],
      bannerList: [],
      thumbnailUri:
        process.env.VUE_APP_BACKHOST +
        ":" +
        process.env.VUE_APP_BACKPORT +
        "/videos/sr_",
      videoUrl: "",
      networks: [],
      userActivateStatus: false,
      addetail: {},
      filter: {},
      linkschain: [],
      allLinks: [],
      user: "",
      user_status: false,
      fields: [
        {
          key: "link",
          label: "Links",
          sortable: true,
          editable: true,
          tbClass: "bTableThStyle",
        },
        {
          key: "copy",
          label: "action",
          tbClass: "acClass",
        },
      ],
    };
  },
  mounted() {
    // this.getLinksData();
    this.userActivateStatus = window.sessionStorage.getItem("status");

    this.init();
  },
  methods: {
    openInsightTrx(item) {
      console.log(item);
      const url = this.$router.resolve({
        name: "InsightDetailedPage",
        query: {
          insight_id: item.insight_id,
        },
      }).href;

      console.log(url);
      window.open(url, "_blank");
      return;
    },
    openServiceDetail(item) {
      const url = this.$router.resolve({
        name: "DetailedPage",
        query: {
          id: item.sid,
        },
      }).href;
      window.open(url, "_blank");
      return;
    },
    async filterData() {
      this.filter.sdate = this.dateRange.startDate;
      this.filter.edate = this.dateRange.endDate;
      this.$root.$emit("date-range-updated", this.dateRange);
      this.init("dateRange");
    },
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;
      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },

    showModal(item) {
      let payload = {
        sid: item.sid,
      };

      this.$root.$emit("showtrxforlist", payload);
      this.$bvModal.show("openSerList");
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async getNetworks() {
      let country = localStorage.getItem("country");
      let netRes = await NetworkTypeService.getAllNetworkType(country);
      if (netRes.result) {
        this.networks = netRes.data;
      }
    },
    async getApkList() {
      let payload = {};
      payload.sdate = this.dateRange.startDate;
      payload.edate = this.dateRange.endDate;
      this.apkListLoading = true;
      try {
        let res = await dashbordService.apkList(payload);
        if (res.result) {
          this.apkList = res.data;
        }
      } catch (e) {
        console.error("error in getting apk data");
        console.log(e);
      }
      this.apkListLoading = false;
    },
    async getInsightsList() {
      let payload = {};
      payload.sdate = this.dateRange.startDate;
      payload.edate = this.dateRange.endDate;
      this.insightLoading = true;
      try {
        let res = await dashbordService.insightsList(payload);
        if (res.result) {
          this.insightsList = res.data;
        }
      } catch (e) {
        console.error("error in getting insights data");
        console.log(e);
      }

      this.insightLoading = false;
    },
    async getBannerList() {
      let payload = {};
      payload.sdate = this.dateRange.startDate;
      payload.edate = this.dateRange.endDate;
      this.browsingLoading = true;
      try {
        let res = await dashbordService.bannerList(payload);
        if (res.result) {
          this.bannerList = res.data;
        }
      } catch (e) {
        console.error("error in getting banner data");
        console.log(e);
      }
      this.browsingLoading = false;
    },
    async init(txt) {
      await this.getNetworks();
      this.getApkList();
      this.getInsightsList();
      this.getBannerList();
    },
    async getLinksData() {
      try {
        this.allLinks = await dashbordService.lists();
        console.log("all data", this.allLinks);
      } catch (e) {
        console.error("ERROR: getting data");
        console.error(e);
      }
    },
    getInsightTestHome(id) {
      return globalConfig.getInsightTestHome + `/${id}`;
    },
    getImageLanding(id) {
      return globalConfig.getLandingImages + `/${id}`;
    },
    ChangedDate() {
      let sdate = new Date(
        this.dateRange.startDate.getTime() -
          this.dateRange.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      let edate = new Date(
        this.dateRange.endDate.getTime() -
          this.dateRange.endDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.dateRange.startDate = sdate;
      this.dateRange.endDate = edate;
      // this.filterData(this.monitor_id, this.dateRange)
    },
    changeDateFormate(d) {
      return moment.utc(d).format("D/M/YYYY");
    },
  },
};
</script>
<style scoped>
.img_custom_style {
  margin-top: 10px;
  min-height: 200px;
  min-width: 100px;
  max-width: 200px;
  border-radius: 0.313vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.img_custom_style:hover {
  cursor: pointer;
  border: 2px solid blue;
}
.showBreadcrumbs {
  display: none !important;
}
@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
